<template>
  <Modal
    max-width="720px"
    :modelValue="isOpen"
    @update:modelValue="closeDialog($event)"
    :align-y="alignY"
    :noSpacing="true"
    modal-transition="slide-up"
    @afterLeave="afterLeave"
  >
    <div class="share-modal-body">
      <div class="clearfix share-modal-link-body">
        <div class="share-modal-item">
          <a
            class="share-modal-icon-box"
            :href="
              `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`
            "
            target="_blank"
          >
            <img src="/img/share-twitter.png" class="share-modal-icon" />
          </a>
          <div class="share-modal-name cWhite">Twitter</div>
        </div>
        <div class="share-modal-item">
          <a
            class="share-modal-icon-box"
            :href="`https://social-plugins.line.me/lineit/share?url=${url}`"
            target="_blank"
          >
            <img src="/img/share-line.png" class="share-modal-icon" />
          </a>
          <div class="share-modal-name cWhite">LINE</div>
        </div>
        <div class="share-modal-item">
          <a
            class="share-modal-icon-box"
            :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
            target="_blank"
          >
            <img src="/img/share-facebook.png" class="share-modal-icon" />
          </a>
          <div class="share-modal-name cWhite">facebook</div>
        </div>
        <div class="share-modal-item">
          <a
            class="share-modal-icon-box"
            :href="`mailto:?subject=${text}&body=${url}`"
            target="_blank"
          >
            <img src="/img/share-mail.png" class="share-modal-icon" />
          </a>
          <div class="share-modal-name cWhite">メール</div>
        </div>
        <div class="share-modal-item">
          <a class="share-modal-icon-box" href="javascript:;" @click="copy">
            <img src="/img/share-link.png" class="share-modal-icon" />
          </a>
          <div class="share-modal-name cWhite">リンクをコピー</div>
        </div>
      </div>
      <div>
        <button type="button" class="share-modal-cancel" @click="cancel">
          キャンセル
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import { Modal } from 'vue-neat-modal'
import { closeOutline, helpOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'ShareModal',
  props: {
    isOpen: { type: Boolean, default: false },
    alignY: { type: String, default: 'bottom' },
    url: { type: String, default: '' },
    text: { type: String, default: '' },
    hashtags: { type: String, default: '' }
  },
  data() {
    return {
      closeOutline,
      helpOutline
    }
  },
  created() {},
  mounted() {},
  //   emits: ['update:isOpen'],
  components: {
    Modal
  },
  methods: {
    closeDialog(e) {
      this.$emit('update:isOpen', e)
    },
    copy() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.url).then(() => {
          this.$toast.success('コピーしました')
        })
      } else {
        this.$toast.success('コピー失敗しました')
      }
    },
    cancel() {
      this.$emit('update:isOpen', false)
    },
    afterLeave() {
      this.$emit('afterLeave')
    }
  }
})
</script>

<style scoped>
.share-modal-link-body {
  width: 100%;
  padding: 0 10px;
}
.share-modal-body {
  width: 100%;
  background: #191919;
}
.share-modal-item {
  width: 20%;
  padding: 20px 1% 8px 1%;
  text-align: center;
  float: left;
}
.share-modal-icon-box {
  width: 100%;
  display: block;
}
.share-modal-icon {
  width: 100%;
  max-width: 50px;
  height: auto;
}
.share-modal-name {
  font-size: 12px;
  line-height: 14px;
  padding-top: 5px;
}
.share-modal-cancel {
  width: 100%;
  height: 69px;
  background-color: #4b4a4b;
  color: #fff;
}
</style>
