<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title class="tc cWhite">{{ eventExpress }}終了</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="common-body-720-90 pb40 streaming-end-big-body">
        <div class="tc streaming-end-top-text-body cWhite">
          <div class="streaming-end-top-text-title">
            {{ eventExpress }}お疲れさまでした！
          </div>
          <div class="f12 tc streaming-end-top-text">
            画像を保存してSNS等でみんなに自慢しよう！
          </div>
        </div>
        <div class="tc streaming-end-canvas-card">
          <div v-show="cerURL" class="streaming-end-cer-img-body">
            <img :src="cerURL" />
          </div>
          <div class="streaming-end-cer-loading">
            参加証作成中...
          </div>
          <canvas width="574" height="828" id="cardCanvas"></canvas>
        </div>
        <div class="streaming-end-rebuy-btn-body" v-if="isBan">
          <ion-button
            expand="block"
            class="streaming-end-rebuy-btn"
            @click="goContact"
            >お問い合わせへ</ion-button
          >
        </div>
        <div v-else class="clearfix">
          <div class="streaming-end-46-btn">
            <ion-button
              expand="block"
              class="streaming-end-rebuy-btn"
              @click="nextEvent"
              >次のイベントへ</ion-button
            >
          </div>
          <!-- <div class="streaming-end-46-btn">
            <ion-button
              expand="block"
              class="streaming-end-rebuy-btn"
              @click="back"
              >チャットルームに戻る</ion-button
            >
          </div> -->
        </div>
        <div class="f12 tc cWhite" v-if="!isBan">
          <div class="dib pointer mt5">
            <a
              href="javascript:void(0)"
              @click="goContact"
              class="cWhite noUnderline"
              >{{ eventExpress }}に関するお問い合わせはこちら＞＞</a
            >
          </div>
        </div>
      </div>
    </ion-content>
    <share-modal
      v-model:isOpen="shareIsOpen"
      :url="'weshow.com'"
      :text="`${eventExpress}参加証`"
    ></share-modal>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle
} from '@ionic/vue'
import {
  chevronBackOutline,
  bookmarkSharp,
  downloadOutline,
  shareSocialOutline,
  shareSocial,
  homeOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import ShareModal from '../../../components/ShareModal.vue'
import moment from 'moment'
import { authApi } from '../../../api'
import { addRefreshJWT } from '../../../utils/utils'

export default defineComponent({
  name: 'StreamingEnd',
  data() {
    return {
      isBan: this.$route.query.type === '1',
      cerURL: null,
      shareIsOpen: false,
      chevronBackOutline,
      bookmarkSharp,
      downloadOutline,
      shareSocialOutline,
      shareSocial,
      homeOutline,
      ticketInfo: {
        title: '第一回ファン感謝祭',
        userName: 'ユーザーX',
        name: '齋れいな',
        avatar:
          'https://gravatar.com/avatar/dba6bae8c566f9d3041fb9cd9ada7741?d=identicon&f=y',
        no: '16/200',
        date: '2020.05.23',
        startTime: '17:30:20',
        endTime: '17:30:50'
      },
      showCard: true,
      id: this.$route.params.id
    }
  },
  computed: {
    eventExpress() {
      return this.$store.getters.eventExpress
    },
    serverDelay() {
      return this.$store.getters.serverDelay
    },
    canRebuy() {
      if (this.meetingEvent) {
        let saleEndTime = this.meetingEvent.saleEndTime
        const s = moment(saleEndTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        if (s > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    canBack() {
      if (this.meetingEvent) {
        let endTime = this.meetingEvent.endTime
        const s = moment(endTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        if (s > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    meetingEvent() {
      return this.$store.getters.meetingEvent
    },
    meetingeEnterInfo() {
      return this.$store.getters.meetingeEnterInfo
    },
    meetingUser() {
      return this.$store.getters.meetingUser
    }
  },
  ionViewWillEnter() {
    this.init()
  },
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    ShareModal
  },
  methods: {
    nextEvent() {
      location.href = 'https://online-talk.jp/history'
    },
    goContact() {
      location.href = 'https://app.chatplus.jp/chat/visitor/f382210a_1?t=btn'
    },
    home() {
      this.$router.replace({
        name: `IdolPage`
      })
    },
    share() {
      this.shareIsOpen = true
    },
    initDraw() {
      // background
      const canvas = document.getElementById('cardCanvas')
      const context = canvas.getContext('2d')
      this.drawsq(context, 0, 0, 574, 828, 40, '#7c3555')
      this.drawsq(context, 38 * 2, 360 * 2, 210 * 2, 32 * 2, 32, '#98677d')
      this.drawsq(context, 102 * 2, 339 * 2, 83 * 2, 26 * 2, 26, '#e05193')
      this.drawDashedLine(context, [10, 10], '#ffffff', 37 * 2, 94 * 2, 213 * 2)
      this.drawText(
        context,
        `${this.eventExpress}参加証`,
        '#ffffff',
        'center',
        18 * 2,
        144 * 2,
        73 * 2
      )
      this.drawText(
        context,
        '参加時間',
        '#ffffff',
        'center',
        12 * 2,
        144 * 2,
        353 * 2
      )
      this.drawText(
        context,
        `${moment(this.meetingeEnterInfo.endTime).format('HH:mm:ss')}`,
        '#ffffff',
        'center',
        14 * 2,
        144 * 2,
        377 * 2
      )
      this.drawText(
        context,
        this.meetingEvent.liver.nickName + `の${this.eventExpress}`,
        '#ffffff',
        'center',
        14 * 2,
        144 * 2,
        210 * 2
      )
      if (Number(this.meetingeEnterInfo.index) === 1) {
        this.drawText(
          context,
          `FIRST`,
          '#ffffff',
          'left',
          14 * 2,
          37 * 2,
          236 * 2
        )
      }
      this.drawText(
        context,
        `${this.meetingUser.nickName}さん`,
        '#ffffff',
        'left',
        14 * 2,
        67 * 2,
        258 * 2
      )
      this.drawText(context, 'DATE', '#ffffff', 'left', 14 * 2, 37 * 2, 282 * 2)
      this.drawText(
        context,
        moment(this.meetingeEnterInfo.startTime).format('YYYY.MM.DD'),
        '#ffffff',
        'left',
        14 * 2,
        67 * 2,
        306 * 2
      )
      const avatar = new Image()
      avatar.setAttribute('crossOrigin', 'anonymous')
      avatar.src = this.meetingEvent.liver.avatar || '/img/defaultlogo.png'
      const promiseAvatar = new Promise(resolve => {
        avatar.onload = () => {
          this.drawAvatar(
            context,
            avatar,
            avatar.width,
            avatar.height,
            0,
            0,
            106 * 2,
            115 * 2,
            74 * 2,
            74 * 2
          )
          resolve()
        }
      })

      const logo = new Image()
      logo.src = '/img/sample gold.png'
      const promiseLogo = new Promise(resolve => {
        logo.onload = () => {
          this.drawImage(
            context,
            logo,
            0,
            0,
            logo.width,
            logo.height,
            122 * 2,
            24 * 2,
            42 * 2,
            26 * 2
          )
          resolve()
        }
      })
      Promise.all([promiseAvatar, promiseLogo]).then(() => {
        const cert = canvas.toDataURL('image/png')
        this.cerURL = cert
        // this.cerURL = cert
        // this.uploadCert(cert)
      })
    },
    uploadCert(cert) {
      authApi
        .ticketsCertificate(
          this.meetingeEnterInfo.ticket.eventId,
          this.meetingeEnterInfo.ticket.id,
          { cert: cert }
        )
        .then(res => {
          const url = res.data.url
          this.cerURL = url
        })
        .catch(error => {
          const status = error.response.status
          switch (status) {
            case 401:
              addRefreshJWT(() => {
                this.uploadCert(cert)
              })
              break

            default:
              break
          }
        })
    },
    drawText(ctx, text, color, align, fontSize, x, y) {
      ctx.fillStyle = color
      ctx.textAlign = align
      ctx.textBaseline = 'middle'
      ctx.font = fontSize + 'px Helvetica Neue, sans-serif'
      ctx.fillText(text, x, y)
    },
    drawDashedLine(ctx, pattern, color, x, y, w) {
      ctx.strokeStyle = color
      ctx.beginPath()
      ctx.setLineDash(pattern)
      ctx.moveTo(x, y + 0.5)
      ctx.lineTo(w + x, y + 0.5)
      ctx.lineWidth = 2
      ctx.stroke()
      ctx.setLineDash([])
    },
    drawAvatar(
      ctx,
      img,
      avatarurl_width,
      avatarurl_heigth,
      avatarurl_x,
      avatarurl_y,
      dx,
      dy,
      dw,
      dh
    ) {
      // debugger
      ctx.save()
      ctx.beginPath() //开始绘制
      //先画个圆  前两个参数确定了圆心 （x,y） 坐标  第三个参数是圆的半径  四参数是绘图方向  默认是false，即顺时针
      // ctx.moveTo(dx, dy)
      ctx.arc(dw / 2 + dx, dh / 2 + dy, dw / 2, 0, Math.PI * 2, false)
      ctx.clip() //画好了圆 剪切  原始画布中剪切任意形状和尺寸。一旦剪切了某个区域，则所有之后的绘图都会被限制在被剪切的区域内 这也是我们要save上下文的原因
      // ctx.stroke()
      this.drawImage(
        ctx,
        img,
        avatarurl_x,
        avatarurl_y,
        avatarurl_width,
        avatarurl_heigth,
        dx,
        dy,
        dw,
        dh
      ) // 推进去图片，必须是https图片
      ctx.restore() //恢复之前保存的绘图上下文 恢复之前保存的绘图上下午即状态 还可以继续绘制
    },
    drawImage(context, img, sx, sy, sw, sh, dx, dy, dw, dh) {
      context.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh)
    },
    drawsq(context, x, y, w, h, r, color) {
      context.beginPath()
      context.lineWidth = 1
      context.strokeStyle = color
      context.fillStyle = color
      context.moveTo(x, y + r)
      context.arc(x + r, y + h - r, r, Math.PI, Math.PI * 0.5, true)
      context.arc(x + w - r, y + h - r, r, Math.PI * 0.5, 0, 1)
      context.arc(x + w - r, y + r, r, 0, Math.PI * 1.5, 1)
      context.arc(x + r, y + r, r, Math.PI * 1.5, Math.PI, 1)
      context.closePath()
      context.stroke()
      context.fill()
    },
    init() {
      if (
        this.meetingeEnterInfo &&
        this.meetingEvent &&
        this.meetingUser &&
        this.eventExpress
      ) {
        this.initDraw()
      } else {
        setTimeout(() => {
          this.init()
        }, 25)
      }
    },
    buyTicket() {
      location.href = `/user/ticketdetail/${this.id}`
      // this.$router.replace({ path: `/user/ticketdetail/${this.id}` })
    },
    back() {
      // location.href = `/user/meeting/${this.id}/chatroom/?rebuy=1`
      this.$router.replace({
        path: `/user/meeting/${this.id}/chatroom/`
        // query: { rebuy: '1' }
      })
      this.$store.dispatch('addUpdateKey')
    }
  }
})
</script>

<style scoped>
.streaming-end-46-btn {
  width: 287px;
  margin: 0 auto;
}
.streaming-end-top-text {
  width: 287px;
  margin: 0 auto;
}
.streaming-end-canvas-card-share {
  position: absolute;
  z-index: 1;
  width: 78px;
  height: 21px;
  background-color: #e05193;
  border-radius: 5px;
  right: 14px;
  top: 7px;
  color: #fff;
  font-size: 12px;
  padding-bottom: 5px;
}
.streaming-end-canvas-card {
  position: relative;
  z-index: 1;
  width: 287px;
  margin: 0 auto;
}
.streaming-end-cer-loading {
  color: #fff;
  position: absolute;
  z-index: 2;
  height: 28px;
  line-height: 28px;
  top: 50%;
  margin-top: -14px;
  width: 100%;
}
.streaming-end-cer-img-body img {
  pointer-events: all;
  -webkit-touch-callout: default !important;
  /* user-select: unset; */
}

.streaming-end-cer-img-body {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#cardCanvas {
  width: 287px;
  height: 414px;
  opacity: 0;
  position: relative;
  z-index: 1;
}
.streaming-end-img-ico-body {
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: 5px;
  font-size: 16px;
}
.streaming-end-img-body {
  position: relative;
  z-index: 1;
}
.streaming-end-card-v2-time {
  width: 210px;
  height: 32px;
  background-color: #98677d;
  border-radius: 13px;
  line-height: 32px;
  text-align: center;
  margin-top: -5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}
.streaming-end-card-v2-time-body {
  margin-top: 15px;
}
.streaming-end-card-v2-time-title {
  width: 83px;
  height: 26px;
  background-color: #e05193;
  border-radius: 13px;
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.streaming-end-card-v2-info-text {
  padding-left: 28px;
}
.streaming-end-card-v2-info-body {
  margin-top: 10px;
  line-height: 24px;
}
.streaming-end-card-v2-head-title {
  font-size: 18px;
}
.streaming-end-card-img-v2 {
  width: 74px;
  height: 74px;
  border-radius: 74px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.streaming-end-card-v2-head {
  padding-bottom: 10px;
  border-bottom: 1px dashed #fff;
}
.streaming-end-card-v2-head-logo {
  width: 42px;
  margin-bottom: 10px;
}
.streaming-end-card-v2-body {
  width: 287px;
  background-color: #7c3555;
  border-radius: 23px;
  padding: 23px 37px;
  margin: 0 auto;
}

.streaming-end-rebuy-btn-body {
  width: 75%;
  margin: 0px auto 0 auto;
}
ion-button.streaming-end-rebuy-btn {
  font-size: 13px;
  margin-top: 8px;
  height: 40px;
}
.streaming-end-rebuy-content {
  margin-top: 15px;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
.streaming-end-rebuy-body {
  margin-top: 5px;
}
.streaming-end-rebuy-title {
  line-height: 52px;
  text-align: center;
  color: #fff;
}
.streaming-end-card-right-time {
  text-align: center;
  padding-top: 18px;
}
.streaming-end-card-right-time-title {
  width: 106px;
  height: 18px;
  background-color: #4b4a4b;
  border-radius: 9px;
  text-align: center;
  font-size: 8px;
  line-height: 18px;
  color: #fff;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -53px;
}
.streaming-end-card-right-time-box {
  height: 46px;
  background-color: #d8d8d8;
  border-radius: 20px;
  margin-top: 5px;
  position: relative;
  z-index: 1;
}
.streaming-end-card-right-label {
  font-size: 8px;
  color: #8a8a8a;
}
.streaming-end-card-name {
  color: #191919;
}
.streaming-end-card-right {
  color: #191919;
  padding-top: 10px;
  padding-right: 20px;
}
.streaming-end-card-img {
  width: 104px;
  height: 104px;
  border-radius: 52px;
  margin-bottom: 5px;
}
.streaming-end-card-left {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 0px;
  width: 128px;
  padding: 12px 8px 8px 8px;
}
.streaming-end-card-body {
  padding-left: 130px;
  position: relative;
  z-index: 1;
  min-height: 160px;
  background-color: #ffffff;
}
.streaming-end-card-head-icon {
  font-size: 24px;
}
.streaming-end-card-logotitle {
  width: 82px;
}
.streaming-end-card {
  max-width: 357px;
  width: 100%;
  margin: 0 auto;
}
.streaming-end-card-head {
  height: 46px;
  background-color: #e05193;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  justify-content: space-around;
  display: flex;
  align-items: center;
}
.streaming-end-top-text-body {
  /* padding-top: 10px; */
  padding-bottom: 8px;
}
.streaming-end-top-text-title {
  font-size: 16px;
  padding: 10px 0;
}
</style>
